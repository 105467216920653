<template>
  <main class="mission-page page">
    <BreadcrumbsComponent title="Миссия театра" />
    <div class="container-padding">
      <div class="mission-page__inner">
        <span>
          Миссия театра - воспитание и развитие восприятия высоких ценностей искусства, обогащение ими
          посетителей театра, посредством сценического театрального искусства. Миссия театра направлена на
          сохранение культурных ценностей и развитие деятельности в сфере культуры и образования в области
          культуры и искусства. Цель театра - обеспечение конституционного права граждан на свободу
          творчества, участия в культурной жизни и пользование услугами, предоставляемыми театром, равного
          доступа к сценическому искусству. Развитие духовного, нравственного и культурного потенциала
          зрителей средствами и возможностями классического и современного театрального искусства. Приобщение
          зрителей к ценностям отечественной и мировой театральной культуры. Свою миссию в сфере культуры ГБУ
          РД «Дагестанский театр оперы и балета» видит в активном вовлечении аудитории в жизнь театра. Театр
          стремится не только удовлетворять потребность общества в классике, но и влиять на формирование
          зрительского вкуса, позволяет публике знакомиться с новым прочтением популярных произведений.
        </span>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import DEFAULT from "gql/queries/default.graphql";

export default {
  name: "MissionPage",
  metaInfo: {
    title: "Миссия театра",
  },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEFAULT,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      });
  },
  components: {
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.mission-page {

  &__inner {
    padding 50px 0 0 0
  }
}
</style>
